.App {
  text-align: center;  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.card-title {
  color: white !important;  
  text-transform: capitalize;
  font-style: italic;
  /* text-shadow: 1px 1px 5px black; */
}

.navbar {
  /* text-shadow: 2px 2px 3px black; */
  border-radius: 10px !important;
  background-color: #191B1F !important;
}

.devicesettingscontainer{
  background-color: #203231;
  height: 100%;
}

.navbar-brand{
  border-right: 0px;
}

.nav-link {
  border-radius: 20px !important;
  margin-left: 2px;
  border: none !important;
  color:#f0e67c !important;
}


.card{
  border-radius: 20px !important;
  padding-left: 0.5vw;
  background-color: #191B1F;
}

/*Setting styling and adding some shadow to the Eqon logo*/
.eqon-logo {
  /* -webkit-filter: drop-shadow(5px 5px 2px #222222);
  filter: drop-shadow(5px 5px 2px #222222); */
  width: 100px;
  margin-right: 2vw;
}

.card-subtitle{
  text-transform: capitalize;
}

